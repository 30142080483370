<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Tabulator - Developer Documentation" />
    <!--EOC-->
    <v-row>
      <v-col cols="12" sm="3">
        <TheLeftSidebar :leftMenuItems="leftmenu"></TheLeftSidebar>
      </v-col>

      <v-col cols="12" sm="9">
        <v-card min-height="70vh" rounded="lg">
          <v-card-title>Columns</v-card-title>
          <v-card-text>
            <p>
              In Tabulator columns are used to define how data loaded into your
              table should be displayed
            </p>

            <p>
              Each column should represent a field from the row data that you
              load into your table, though you do not need a column for each
              field in your data, only for fields that you want to display in
              your table.
            </p>

            <p>
              Columns have a wide variety of configuration options to allow you
              to customize the table to your usage case.
            </p>

            <h6>Example Table With Filter</h6>

            <BreadTabulatorFilterable
              ref="filter"
              :model="model"
              :setConditions="setConditions"
            ></BreadTabulatorFilterable>

            <div class="px-3 py-1 border-b text-caption">
              {{ conditions.length }} filters.
            </div>

            <div>
            <div id="pagination-counter" style="float: left; margin-right: 2px;"></div>
            <span id="record-count" style="float: left;font-size: 14px; margin-top: 2px;"></span>
            <div style="clear: both;"></div>
          </div>

            <BreadTabulator
              ref="tabulatorDataTable"
              layout="fitColumns"
              :autoColumns="false"
              :paginationSize="this.paginationSize"
              :paginationSizeSelector="this.paginationSizeSelector"
              :apiUrl="this.apiGetBrowseUrl()"
              :columns="this.columns"
              modelKey="demoProduct"
            ></BreadTabulator>

            <p>
              The column definitions are provided to Tabulator in the columns
              property of the table constructor object and should take the
              format of an array of objects, with each object representing the
              configuration of one column.
            </p>
            <p>
              I used model header array to display the columns.
            </p>

            <CopyToClipBoard
              :text-to-copy="tabulatorColumnsExample"
            ></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>
                  
                      {{ tabulatorColumnsExample }}
                    </pre
              >
            </VueCodeHighlight>

            <p>If you wants to add a column with some functions and routes, you can use following code</p>

            
            <CopyToClipBoard
              :text-to-copy="columnPushCode"
            ></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>
                  
                      {{ columnPushCode }}
                    </pre
              >
              FORMATTER CODE = `<span>Action</span>`
            </VueCodeHighlight>
            
            <TheColumnContent></TheColumnContent>

            <p>
              we are going to use the common component
              (BreadTabulatorFilterable.vue) to create the filters.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
    
    <script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
import TheLeftSidebar from "../components/PageDeveloperTabulator/TheLeftSidebar.vue";
import leftmenu from "../config/leftmenu.js";
import Service from "@/objects/service";
import CopyToClipBoard from "../components/PageDeveloperTabulator/CopyToClipBoard.vue";
import TheColumnContent from "../components/PageDeveloperTabulator/TheColumnContent.vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/prism-okaidia.css";
//import DeveloperRouter from "../../developer/router/index.js";
/* import "vue-code-highlight/themes/window.css"; */
//BOC:[model]
import Model from "@/objects/model";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    TheLeftSidebar,
    CopyToClipBoard,
    VueCodeHighlight,
    TheColumnContent,
  },
  computed: mapState({
    //
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    leftmenu: leftmenu,

    //BOC:[service]
    service: new Service(),
    //EOC

    //BOC:[model]
    model: new Model(),
    //EOC

    filters: [],

    conditions: [],

    tabulatorColumnsExample: `
    headers: [
        {
          title: "ID",
          field: "id",
          dataType: "interger",
          filterable: false,
          headerFilter: false,
          hozAlign: "left", 
        },
        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          headerFilter: false,
          hozAlign: "left",
          editor: "input",
        },
        {
          title: "Cost",
          field: "cost",
          dataType: "float",
          filterable: true,
          headerFilter: false,
          formatter: "money",
          hozAlign: "right",
          editor: "number",
        },
        {
          title: "Price",
          field: "price",
          dataType: "float",
          filterable: true,
          headerFilter: false,
          hozAlign: "right",
          formatter: (cell) => {
            if (cell.getValue() > 900) {
              return "<span style='color:red; font-weight:bold;'>" + cell.getValue() + "</span>"
            }else{
            return cell.getValue();
            }
          },
          editor: "number",
        },

        {
          title: "Stock Count",
          field: "stock",
          dataType: "integer",
          filterable: true,
          headerFilter: false,
          formatter: "progress",
          formatterParams: {
            color: ["green", "orange", "red"],
            legendColor: "#000000",
            legendAlign: "center",
          },
          editor: "number",
        },

        {
          title: "Availability",
          field: "instock",
          dataType: "boolean",
          filterable: true,
          headerFilter: false,
          headerSort: false,
          hozAlign:"center", 
          editor:true, 
          formatter:"tickCross"
        },

        {
          title: "Rating",
          field: "rating",
          dataType: "float",
          filterable: false,
          headerFilter: false,
          formatter: "star",
          editor: "number",
        },

      ]`,

      columnPushCode: 
      `
      this.columns.push({
      title: "Actions",
      filterable: false,
      headerSort: false,
      editor: "list",
      editorParams: {
        values: [
          {
            label: "View",
            value: "view",
          },
          {
            label: "Edit",
            value: "edit",
          },
          {
            label: "Delete",
            value: "delete",
          },
        ],
      },
      formatter: () => {
        return FORMATTER CODE;
      },

      cellEdited: (cell) => {
        var cellValue = cell.getValue();
        switch (cellValue) {
          case "view":
            this.$router.push({
              name: "PageDeveloperComponentBreadTabulatorColumnsView",
              params: {
                columnKey: cell.getRow().getData().id,
              },
            });
            break;
          case "edit":
            alert("Editing row data");

            break;
          case "delete":
            alert("Deleting row data");
            break;
          default:
            break;
        }
      },
    });`,

    paginationSizeSelector: [10, 20, 30, 40, 50],
    paginationSize: 10,
    columns: [],
    userRole: "admin",
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "BreadTabulator",
      to: { name: "PageDeveloperComponentBreadTabulator" },
      exact: true,
    });

    this.breadcrumb.add({
      text: "Columns",
      to: { name: "" },
      exact: true,
    });
    //EOC

    //BOC:[model]
    this.model.getByKey("demoProduct");
    //EOC
    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    this.columns.push({
      title: "Actions",
      filterable: false,
      headerSort: false,
      editor: "list",
      editorParams: {
        valuesLookup: () =>{
        if (this.userRole === "admin") {
            return [
                {
                  label: "View",
                  value: "view",
                },
                {
                  label: "Edit",
                  value: "edit",
                },
                {
                  label: "Delete",
                  value: "delete",
                },
            ];
        } else if (this.userRole === "user") {
            return [
                {
                  label: "View",
                  value: "view",
                },
            ];
        }
        
    }

      },
      formatter: () => {
        return `<span class="">Action</span>`;
      },

      cellEdited: (cell) => {
        var cellValue = cell.getValue();
        switch (cellValue) {
          case "view":
            this.$router.push({
              name: "PageDeveloperComponentBreadTabulatorColumnsView",
              params: {
                columnKey: cell.getRow().getData().id,
              },
            });
            break;
          case "edit":
            alert("Editing row data");

            break;
          case "delete":
            alert("Deleting row data");
            break;
          default:
            break;
        }
      },
    });

    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
  },
  mounted() {
    //
  },
  methods: {
    apiGetBrowseUrl() {
      return `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/list`;
    },

    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    //EOC
    //BOC:[conditions]
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },
  },
};
</script>
  
  <style scoped>
</style>