<template>
  <div>
    <p>
      There are a large number of properties you can choose from to customize
      your columns:
    </p>

    <h6>General</h6>

    <ul>
      <li>
        <strong>title -</strong> <b><i>Required</i></b> This is the title that
        will be displayed in the header for this column
      </li>
      <li>
        <strong>field - </strong><b><i>Required</i></b> (not required in
        icon/button columns) this is the key for this column in the data array
      </li>
      <li>
        <strong>visible -</strong> (boolean, default - true) determines if the
        column is visible.
      </li>
    </ul>

    <h6>Layout</h6>

    <ul>
      <li>
        <strong>hozAlign</strong>&nbsp;- sets the horizontal text alignment for
        this column (left|center|right)
      </li>
      <li>
        <strong>vertAlign</strong>&nbsp;- sets the vertical text alignment for
        this column (top|middle|bottom)
      </li>
      <li>
        <strong>headerHozAlign</strong>&nbsp;- sets the horizontal text
        alignment for this columns header title (left|center|right)
      </li>
      <li>
        <strong>width</strong>&nbsp;- sets the width of this column, this can be
        set in pixels or as a percentage of total table width (if not set the
        system will determine the best)
      </li>
      <li>
        <strong>minWidth</strong>&nbsp;- sets the minimum width of this column,
        this should be set in pixels
      </li>
      <li>
        <strong>maxWidth</strong>&nbsp;- sets the maximum width of this column,
        this should be set in pixels
      </li>
      <li>
        <strong>maxInitialWidth</strong>&nbsp;- sets the maximum width of this
        column when it is first rendered, the user can then resize to above this
        (up to the maxWidth, if set) this should be set in pixels
      </li>
      <li>
        <strong>widthGrow&nbsp;</strong>- when using&nbsp;<span class="param"
          >fitColumns</span
        >&nbsp;layout mode, determines how much the column should grow to fill
        available space (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/layout"
          >Table Layout</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>widthShrink&nbsp;</strong>- when using&nbsp;<span class="param"
          >fitColumns</span
        >&nbsp;layout mode, determines how much the column should shrink to fit
        available space (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/layout"
          >Table Layout</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>resizable&nbsp;</strong>- set whether column can be resized by
        user dragging its edges (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/layout#resize-column"
          >Table Layout</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>frozen</strong>&nbsp;- freezes the column in place when
        scrolling (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/layout#frozen-column"
          >Frozen Columns</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>responsive</strong>&nbsp;- an integer to determine when the
        column should be hidden in responsive mode (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/layout#responsive"
          >Responsive Layout</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>tooltip</strong>&nbsp;- sets the on hover tooltip for each cell
        in this column (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cssClass</strong>&nbsp;- sets css classes on header and cells in
        this column.&nbsp;<em
          >(value should be a string containing space separated class names)</em
        >
      </li>
      <li>
        <strong>rowHandle&nbsp;</strong>- sets the column as a row handle,
        allowing it to be used to drag movable rows. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/move"
          >Movable Rows</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>htmlOutput</strong>&nbsp;- show or hide column in the getHtml
        function output (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/update#retrieve-html"
          >Retrieve Data as HTML Table</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>print</strong>&nbsp;- show or hide column in the print output
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/print">Printing</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>clipboard</strong>&nbsp;- show or hide column in the clipboard
        output (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/clipboard#visbility"
          >Clipboard</a
        >&nbsp;for more details)
      </li>
    </ul>

    <h6>Data Manipulation</h6>

    <ul>
      <li>
        <strong>sorter</strong>&nbsp;- determines how to sort data in this
        column (see&nbsp;<a href="https://tabulator.info/docs/5.5/sort"
          >Sorting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>sorterParams&nbsp;</strong>- additional parameters you can pass
        to the sorter(see&nbsp;<a href="https://tabulator.info/docs/5.5/sort"
          >Sorting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatter</strong>&nbsp;- set how you would like the data to be
        formatted (see&nbsp;<a href="https://tabulator.info/docs/5.5/format"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatterParams</strong>&nbsp;- additional parameters you can
        pass to the formatter(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatterPrint</strong>&nbsp;- set how you would like the data
        to be formatted when the table is printed(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format#format-export"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatterPrintParams</strong>&nbsp;- additional parameters you
        can pass to the print formatter(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format#format-export"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatterClipboard</strong>&nbsp;- set how you would like the
        data to be formatted when copied to the clipboard(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format#format-export"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatterClipboardParams</strong>&nbsp;- additional parameters
        you can pass to the clipboard formatter(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format#format-export"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatterHtmlOutput</strong>&nbsp;- set how you would like the
        data to be formatted when the getHtml function is used(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format#format-export"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>formatterHtmlOutputParams</strong>&nbsp;- additional parameters
        you can pass to the html output formatter(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format#format-export"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>variableHeight&nbsp;</strong>-alter the row height to fit the
        contents of the cell instead of hiding overflow
      </li>
      <li>
        <strong>editable&nbsp;</strong>- callback to check if the cell is
        editable (see&nbsp;<a href="https://tabulator.info/docs/5.5/edit"
          >Manipulating Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>editor</strong>&nbsp;- set the editor to be used when editing
        the data. (see&nbsp;<a href="https://tabulator.info/docs/5.5/edit"
          >Manipulating Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>editorParams&nbsp;</strong>- additional parameters you can pass
        to the editor (see&nbsp;<a href="https://tabulator.info/docs/5.5/edit"
          >Manipulating Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>validator&nbsp;</strong>- set the validator to be used to
        approve data when a user edits a cell. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/validate"
          >Manipulating Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>contextMenu</strong>&nbsp;- add context menu to column cells
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/menu#cell-context"
          >Cell Menus</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>clickMenu</strong>&nbsp;- add left click menu to column cells
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/menu#cell-context"
          >Cell Menus</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>dblClickMenu</strong>&nbsp;- add double left click menu to
        column cells (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/menu#cell-context"
          >Cell Menus</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>contextPopup</strong>&nbsp;- add context popup to column cells
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/menu#popup-cell"
          >Cell Popups</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>clickPopup</strong>&nbsp;- add left click popup to column cells
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/menu#popup-cell"
          >Cell Popups</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>dblClickPopup</strong>&nbsp;- add double left click popup to
        column cells (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/menu#popup-cell"
          >Cell Popups</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutator</strong>&nbsp;- function for manipulating column values
        as they are parsed into the table (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutatorParams&nbsp;</strong>- additional parameters you can pass
        to the mutator(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutatorData&nbsp;</strong>- function for manipulating column
        values as they are parsed into the table by command (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutatorDataParams&nbsp;</strong>- additional parameters you can
        pass to the mutatorData(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutatorEdit&nbsp;</strong>- function for manipulating column
        values as they are edited by a user (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutatorEditParams&nbsp;</strong>- additional parameters you can
        pass to the mutatorEdit(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutatorClipboard&nbsp;</strong>- function for manipulating
        column values as they are pasted by a user (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutatorClipboardParams&nbsp;</strong>- additional parameters you
        can pass to the mutatorClipboard (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>mutateLink&nbsp;</strong>- trigger mutation on columns when this
        column is edited (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/mutators#mutator-link"
          >Mutators</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessor</strong>&nbsp;- function to alter column values before
        they are extracted from the table function (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessorParams&nbsp;</strong>- additional parameters you can
        pass to the accessor(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessorData&nbsp;</strong>- function to alter column values
        before they are extracted from the table using the&nbsp;<span
          class="param"
          >getData</span
        >&nbsp;function (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessorDataParams&nbsp;</strong>- additional parameters you can
        pass to the accessorData(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessorDownload&nbsp;</strong>- function to alter column values
        before they are included in a file download (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessorDownloadParams&nbsp;</strong>- additional parameters you
        can pass to the accessorDownload(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessorClipboard&nbsp;</strong>- function to alter column
        values before they are copied to the clipboard (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>accessorClipboardParams&nbsp;</strong>- additional parameters
        you can pass to the accessorClipboard(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/modules#accessors"
          >Accessors</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>download&nbsp;</strong>- show or hide column in downloaded data
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/download"
          >Downloading Table Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>titleDownload&nbsp;</strong>- set custom title for column in
        download (see&nbsp;<a href="https://tabulator.info/docs/5.5/download"
          >Downloading Table Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>topCalc&nbsp;</strong>- the column calculation to be displayed
        at the top of this column(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>topCalcParams&nbsp;</strong>- additional parameters you can pass
        to the topCalc calculation function (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>topCalcFormatter&nbsp;</strong>- formatter for the topCalc
        calculation cell (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>topCalcFormatterParams&nbsp;</strong>- additional parameters you
        can pass to the topCalcFormatter function(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>bottomCalc&nbsp;</strong>- the column calculation to be
        displayed at the bottom of this column(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>bottomCalcParams&nbsp;</strong>- additional parameters you can
        pass to the bottomCalc calculation function(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>bottomCalcFormatter&nbsp;</strong>- formatter for the bottomCalc
        calculation cell (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>bottomCalcFormatterParams&nbsp;</strong>- additional parameters
        you can pass to the bottomCalcFormatter function(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/column-calcs"
          >Column Calculations</a
        >&nbsp;for more details)
      </li>
    </ul>

    <h6>Cell Events</h6>

    <ul>
      <li>
        <strong>cellClick</strong>&nbsp;- callback for when user clicks on a
        cell in this column (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellDblClick</strong>&nbsp;- callback for when user double
        clicks on a cell in this column (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellContext</strong>&nbsp;- callback for when user right clicks
        on a cell in this column (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellTap&nbsp;</strong>- callback for when user taps on a cell in
        this column, triggered in touch displays. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellDblTap&nbsp;</strong>- callback for when user double taps on
        a cell in this column, triggered in touch displays when a user taps the
        same cell twice in under 300ms. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellTapHold&nbsp;</strong>- callback for when user taps and
        holds on a cell in this column, triggered in touch displays when a user
        taps and holds the same cell for 1 second. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellMouseEnter</strong>&nbsp;- callback for when the mouse
        pointer enters a cell (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellMouseLeave</strong>&nbsp;- callback for when the mouse
        pointer leaves a cell (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellMouseOver</strong>&nbsp;- callback for when the mouse
        pointer enters a cell or one of its child elements(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellMouseOut</strong>&nbsp;- callback for when the mouse pointer
        enters a cell or one of its child elements(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellMouseMove</strong>&nbsp;- callback for when the mouse
        pointer moves over a cell (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellMouseDown</strong>&nbsp;- callback for the left mouse button
        is pressed with the cursor over a cell (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellMouseUp</strong>&nbsp;- callback for the left mouse button
        is released with the cursor over a cell (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#cell"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellEditing&nbsp;</strong>- callback for when a cell in this
        column is being edited by the user (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellEdited&nbsp;</strong>- callback for when a cell in this
        column has been edited by the user (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>cellEditCancelled&nbsp;</strong>- callback for when an edit on a
        cell in this column is aborted by the user (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
    </ul>

    <h6>Column Headers</h6>

    <ul>
      <li>
        <strong>headerSort&nbsp;</strong>- user can sort by clicking on the
        header (see&nbsp;<a href="https://tabulator.info/docs/5.5/sort"
          >Sorting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerSortStartingDir&nbsp;</strong>- set the starting sort
        direction when a user first clicks on a header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/sort"
          >Sorting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerSortTristate&nbsp;</strong>- allow tristate toggling of
        column header sort direction (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/sort"
          >Sorting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerClick</strong>&nbsp;- callback for when user clicks on the
        header for this column (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerDblClick</strong>&nbsp;- callback for when user double
        clicks on the header for this column (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerContext</strong>&nbsp;- callback for when user right
        clicks on the header for this column (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerTap&nbsp;</strong>- callback for when user taps on a
        header for this column, triggered in touch displays. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerDblTap&nbsp;</strong>- callback for when user double taps
        on a header for this column, triggered in touch displays when a user
        taps the same header twice in under 300ms. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerTapHold&nbsp;</strong>- callback for when user taps and
        holds on a header for this column, triggered in touch displays when a
        user taps and holds the same header for 1 second. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMouseEnter</strong>&nbsp;- callback for when the mouse
        pointer enters a column header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#column"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMouseLeave</strong>&nbsp;- callback for when the mouse
        pointer leaves a column header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#column"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMouseOver</strong>&nbsp;- callback for when the mouse
        pointer enters a column header or one of its child elements(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#column"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMouseOut</strong>&nbsp;- callback for when the mouse
        pointer enters a column header or one of its child elements(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#column"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMouseMove</strong>&nbsp;- callback for when the mouse
        pointer moves over a column header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#column"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMouseDown</strong>&nbsp;- callback for the left mouse
        button is pressed with the cursor over a column header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#column"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMouseUp</strong>&nbsp;- callback for the left mouse button
        is released with the cursor over a column header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/callbacks#column"
          >Callbacks</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerTooltip&nbsp;</strong>- sets the on hover tooltip for the
        column header (see&nbsp;<a href="https://tabulator.info/docs/5.5/format"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerVertical&nbsp;</strong>- change the orientation of the
        column header to vertical (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/columns#vertical"
          >Vertical Column Headers</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>editableTitle</strong>&nbsp;- allows the user to edit the header
        titles. (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/columns#edit-titles"
          >Editable Column Titles</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>titleFormatter&nbsp;</strong>- formatter function for header
        title (see&nbsp;<a href="https://tabulator.info/docs/5.5/format"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>titleFormatterParams&nbsp;</strong>- additional parameters you
        can pass to the header title formatter(see&nbsp;<a
          href="https://tabulator.info/docs/5.5/format"
          >Formatting Data</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerWordWrap&nbsp;</strong>- Allow word wrapping in the column
        header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/columns#header-wrap"
          >Header Text Wrapping</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerFilter</strong>&nbsp;- filtering of columns from elements
        in the header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/filter#header"
          >Header Filtering</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerFilterPlaceholder&nbsp;</strong>- placeholder text for the
        header filter (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/filter#header"
          >Header Filtering</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerFilterParams&nbsp;</strong>- additional parameters you can
        pass to the header filter (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/filter#header"
          >Header Filtering</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerFilterEmptyCheck</strong>&nbsp;- function to check when
        the header filter is empty (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/filter#header"
          >Header Filtering</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerFilterFunc</strong>&nbsp;- the filter function that should
        be used by the header filter (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/filter#header"
          >Header Filtering</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerFilterFuncParams</strong>&nbsp;- additional parameters
        object passed to the headerFilterFunc function (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/filter#header"
          >Header Filtering</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerFilterLiveFilter</strong>&nbsp;- disable live filtering of
        the table (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/filter#header"
          >Header Filtering</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerMenu</strong>&nbsp;- add menu button to column header
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/menu#header-menu"
          >Header Menus</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerClickMenu</strong>&nbsp;- add click menu to column header
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/menu#header-context"
          >Header Menus</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerDblClickMenu</strong>&nbsp;- add double click menu to
        column header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/menu#header-context"
          >Header Menus</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerContextMenu</strong>&nbsp;- add context menu to column
        header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/menu#header-context"
          >Header Menus</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerPopup</strong>&nbsp;- add popup button to column header
        (see&nbsp;<a href="https://tabulator.info/docs/5.5/menu#popup-column"
          >Column Header Popups</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerClickPopup</strong>&nbsp;- add click popup to column
        header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/menu#popup-column"
          >Column Header Popups</a
        >&nbsp;for more details)
      </li>
      <li>
        <strong>headerContextPopup</strong>&nbsp;- add context popup to column
        header (see&nbsp;<a
          href="https://tabulator.info/docs/5.5/menu#popup-column"
          >Column Header Popups</a
        >&nbsp;for more details)
      </li>
    </ul>

    <p>
      <strong>Note:</strong> Please refer
      <a href="https://tabulator.info/docs/5.5/columns" target="_blank"
        >Tabulator Column Setup</a
      >
      URL for detail explanation
    </p>
  </div>
</template>

<script>
export default {
  name: "ColumnContent",
};
</script>
